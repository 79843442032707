import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const ImmobilienkreditRechner = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Immobilienkredit-Rechner – jetzt Kredit berechnen mit miracl." showCalc={false} />
            <Article>
                <h2>Der miracl Immobilienkredit-Rechner</h2>
                <p>
                    Du überlegst in eine Immobilie zu investieren? Egal, ob du ein Haus für deine Familie, eine Wohnung
                    in der Stadt für dich selbst kaufen möchtest, oder um sie zu vermieten – in Immobilien zu
                    investieren, ist oft eine gute Entscheidung. Doch der Weg dorthin ist nicht immer leicht. Auch wenn
                    die aktuell sehr niedrigen{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    verlockend sind, sollte die Entscheidung für einen Kredit langfristig ausgerichtet sein und vorher
                    mehrere Angebote miteinander verglichen werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratung anfordern!
                    </a>
                </p>
                <hr />

                <h2>Der erste Schritt zum Kredit - individuell, schnell und unverbindlich.</h2>
                <p>
                    Es gibt unzählige Kreditarten und viele Faktoren, die beim Kauf beachtet werden sollten. Von der
                    Wahl der richtigen{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditsumme
                    </Link>
                    , über die Vereinbarungen zur monatliche Rate bis hin zur Zinsvereinbarung und anderen vertraglichen
                    Regelungen – diese Entschlüsse sollten nicht voreilig getroffen werden.
                </p>
                <p>
                    Egal wie viel Eigenkapital du hast, welche Zinsen du dir wünscht und für welche Art von Kredit du
                    dich entscheidest: Hol dir mit unserem Immobilienkredit-Rechner eine Ersteinschätzung – oder melde
                    dich direkt bei unseren Finanzierungs-Experten und lasse dich individuell beraten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratung anfordern!
                    </a>
                </p>
                <hr />

                <h2>Das kann unser Immobilienkredit-Rechner</h2>
                <p>
                    Mit unserem Immobilienkredit-Rechner kannst du verschiedene Kreditvarianten miteinander vergleichen.
                    Du gibst nur einige Informationen ein und unser Rechner findet für dich sofort die möglichen
                    Kreditvarianten heraus. So kannst du dir bereits im voraus genau ansehen, welche Variante gut zu dir
                    passen würde und dir einen Überblick über deine Möglichkeiten verschaffen. Dafür musst du bloß den
                    Kreditbeitrag, den du dir vorstellst und die Laufzeit, die du dir dafür wünscht, angeben, dann
                    zwischen einem fixen oder variablen Zinssatz auswählen und schon bekommst du dein erstes ganz
                    individuelles Angebot. Das kann dann beim nächsten Schritt sehr hilfreich sein, wenn es zu einem
                    Beratungsgespräch mit einem Finanzexperten oder einer Expertin kommen soll.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <p>Mit nur wenigen Klicks gibt dir unser Immobilienkredit-Rechner Antworten auf die Fragen:</p>
                <ul>
                    <li>Wieviel kann ich mir leisten?</li>
                    <li>Wie wirken sich unterschiedliche Laufzeiten auf meine monatliche Rate aus?</li>
                    <li>Mit welchen Zinsen muss ich ungefähr rechnen?</li>
                </ul>
                <p>
                    Selbstverständlich ist unser Immobilien-Kreditrechner unverbindlich und geht vertraulich mit deinen
                    Daten um.
                </p>
                <hr />

                <h2>Welchen Kredit kann ich mir leisten?</h2>
                <p>
                    Vielleicht weißt du noch nicht, welche monatliche Rate du in den Rechner eingeben sollst. Wieviel du
                    dir im Endeffekt leisten kannst, hängt vor allem von deinem Einkommen ab. Rechne dir aus, wieviel
                    dir jeden Monat übrig bleibt, wenn du deine Ausgaben abziehst und addiere zu diesem Betrag noch
                    einen Puffer von 20 %. Das ist dann die maximale Monatsrate, die du dir leisten kannst. Deine
                    monatlichen Ausgaben reduzieren sich jedoch, sobald deine Miete wegfällt und du sie durch die
                    Betriebskosten deiner neuen Immobilie ersetzt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die Immobilienkredit-Wahl</h2>
                <p>
                    Wenn du weißt wieviel du dir leisten kannst, kannst du dir überlegen, welcher Immobilienkredit am
                    besten zu deiner persönlichen Situation passt. Unser Immobilienkredit-Rechner zeigt dir hierbei
                    unterschiedliche Optionen. Bei der Suche nach einem konkreten Kreditangebot einer Bank, solltest du
                    dich jedoch bestenfalls von Finanzexpert*innen beraten lassen. Diese habe die nötige Erfahrung, um
                    deine ganz individuelle Situation einzuschätzen und die die günstigste Finanzierung zu vermitteln.
                    Es lohnt sich jedenfalls zu wissen, worauf man bei einem Kreditvergleich achten sollte! Dazu findest
                    du auch auf unserer Website einige hilfreiche Artikel.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Probiere hier unseren Immobilienkredit-Rechner aus
                    </a>
                </p>
                <hr />

                <h2>Alles rund um die Immobilienfinanzierung – jetzt bei miracl</h2>
                <p>
                    miracl ist keine Bank. Wir arbeiten zu 100% unabhängig. Unsere Mission? Immobilien-Eigentum für
                    jeden und jede zugänglich zu machen. Wir verbinden dazu jahrelanges Know-How aus der Finanzbranche
                    mit modernster Technologie. Und stellen dabei immer den Menschen in den Mittelpunkt. Probier es aus
                    und melde dich jederzeit mit allen deinen Fragen bei uns!
                </p>
            </Article>
            <MortgageCalculator noTopMargin />

            <BreadcrumbList page={"immobilienkreditRechner"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immobilienkreditRechner"}
                heading={"Immobilienkredit-Rechner – jetzt Kredit berechnen mit miracl."}
            />
        </Layout>
    );
};

export default ImmobilienkreditRechner;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.artikel.immobilienkredit-rechner"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
